import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { differenceInDays, addDays, isFuture } from 'date-fns';

import { client } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { Redirect } from 'cccisd-react-router';
import { Html } from 'cccisd-wysiwyg';

import progressQuery from './progress.graphql';

const Fortress = window.cccisd.fortress;

const Course = () => {
    // gotta be logged in as learner
    if (!Fortress.auth() || Fortress.user.acting.role.handle !== 'learner') {
        return <Redirect to="/" />;
    }

    const pawnId = Fortress.user.acting.id;
    const pawnHash = Fortress.user.acting.random_hash;
    const [isLoading, setIsLoading] = useState(true);

    /* deploymentHandle is one of...
     *   consent, course,
     *   pre1_intervention, post1_intervention, post2_intervention,
     *   pre1_waitlist, pre2_waitlist, post1_waitlist, post2_waitlist
     */
    const [deploymentHandle, setDeploymentHandle] = useState(null);
    const [staticMessage, setStaticMessage] = useState('');

    const supportMessage = `
        <div class="alert alert-info">
            <p>
                This study is not currently open. Thank you for your participation.
            </p>
            <p>
                <b>Questions?</b> Email the study team at: <b>Onlinestudy@jh.edu</b>
                if you have questions or you think this message is shown in error.
            </p>
        </div>
    `;

    async function getData() {
        setIsLoading(true);
        const resp = await client.query({
            query: progressQuery,
            variables: {
                pawnId,
            },
            fetchPolicy: 'network-only',
        });

        const progressInfo = _get(resp, 'data.roles.learner', {});

        const clockStartString = _get(progressInfo, 'user.invitedAt', null) || _get(progressInfo, 'user.activatedAt');
        if (!clockStartString) {
            setStaticMessage(`
                <div class="alert alert-info">
                    <p>
                        You do not have access to this course. Please speak to admin.
                        <b>Questions?</b> Email the study team at:
                        <a href="mailto:onlinestudy@jh.edu">Onlinestudy@jh.edu</a>.
                    </p>
                </div>
            `);
            return;
        }

        const deploymentInfo = _get(resp, 'data.deploymentInfo', {});

        const now = new Date();
        const clockStartDate = new Date(clockStartString);
        const isConsentComplete = _get(progressInfo, 'consent.completed', false);
        const isFirst7Days = differenceInDays(now, clockStartDate) <= 7;

        if (!isConsentComplete) {
            if (isFirst7Days) {
                setDeploymentHandle('consent');
                return;
            }
            const kickoutMessage = _get(deploymentInfo, 'consent.settings.closedMessage', supportMessage);
            setStaticMessage(kickoutMessage);
            return;
        }

        const didConsent = ['1', 1, true].includes(_get(progressInfo, 'consent.devTags.consent', false));
        if (!didConsent) {
            // System will re-branch to the non-consenting message
            setDeploymentHandle('consent');
            return;
        }

        // 'waitlist' or 'intervention'
        const conditionKey = _get(progressInfo, 'consent.conditionKey');
        if (['5', 5].includes(conditionKey)) {
            handleInterventionLogic(deploymentInfo, progressInfo, isFirst7Days);
        } else if (['3', 3].includes(conditionKey)) {
            handleWaitlistLogic(deploymentInfo, progressInfo, isFirst7Days);
        } else {
            setStaticMessage(supportMessage);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (staticMessage || deploymentHandle) {
            setIsLoading(false);
        }
    }, [staticMessage, deploymentHandle]);

    function handleInterventionLogic(deploymentInfo, progressInfo, isFirst7Days) {
        const preCompletedAt = _get(progressInfo, 'pre1_intervention.completedAt');
        if (!preCompletedAt) {
            if (isFirst7Days) {
                setDeploymentHandle('pre1_intervention');
            } else {
                const closedMessage = _get(deploymentInfo, 'pre1_intervention.settings.closedMessage', supportMessage);
                setStaticMessage(closedMessage);
            }

            return;
        }

        const courseWindowMin = new Date(preCompletedAt);
        const courseWindowMax = addDays(preCompletedAt, 28);
        if (!isFuture(courseWindowMin) && isFuture(courseWindowMax)) {
            setDeploymentHandle('course');
            return;
        }

        const post1CompletedAt = _get(progressInfo, 'post1_intervention.completedAt');
        const post1Min = courseWindowMax;
        const post1Max = addDays(courseWindowMax, 28);
        if (!isFuture(post1Min) && isFuture(post1Max)) {
            if (!post1CompletedAt) {
                setDeploymentHandle('post1_intervention');
            } else {
                let completionMsg = '';
                try {
                    completionMsg =
                        deploymentInfo.post1_intervention.settings.branchedCompletionConfig.defaultBranch.conditions.find(
                            cond => Number(cond.key) === 1
                        ).completionMessage;
                } catch (e) {
                    completionMsg = _get(deploymentInfo, 'post1_intervention.settings.completionMessage');
                }
                setStaticMessage(completionMsg || supportMessage);
            }

            return;
        }

        const waitingAroundPeriodMin = post1Max;
        const waitingAroundPeriodMax = addDays(post1Max, 56);
        if (!isFuture(waitingAroundPeriodMin) && isFuture(waitingAroundPeriodMax)) {
            const closedMessage = _get(deploymentInfo, 'post1_intervention.settings.closedMessage', supportMessage);
            setStaticMessage(closedMessage);
            return;
        }

        // no max for post2 (as of December 2024)
        const post2CompletedAt = _get(progressInfo, 'post2_intervention.completedAt');
        const post2Min = waitingAroundPeriodMax;
        if (!isFuture(post2Min)) {
            if (!post2CompletedAt) {
                setDeploymentHandle('post2_intervention');
            } else {
                const completionMessage = _get(
                    deploymentInfo,
                    'post2_intervention.settings.completionMessage',
                    supportMessage
                );
                setStaticMessage(completionMessage);
            }

            return;
        }

        const finalMessage = post2CompletedAt
            ? _get(deploymentInfo, 'post2_intervention.settings.completionMessage')
            : _get(deploymentInfo, 'post2_intervention.settings.closedMessage');
        setStaticMessage(finalMessage || supportMessage);
    }

    function handleWaitlistLogic(deploymentInfo, progressInfo, isFirst7Days) {
        const preCompletedAt = _get(progressInfo, 'pre1_waitlist.completedAt');
        if (!preCompletedAt) {
            if (isFirst7Days) {
                setDeploymentHandle('pre1_waitlist');
            } else {
                const closedMessage = _get(deploymentInfo, 'pre1_waitlist.settings.closedMessage', supportMessage);
                setStaticMessage(closedMessage);
            }

            return;
        }

        const waitPeriod1Min = new Date(preCompletedAt);
        const waitPeriod1Max = addDays(waitPeriod1Min, 28);
        if (!isFuture(waitPeriod1Min) && isFuture(waitPeriod1Max)) {
            let waitingPeriodMessage;
            try {
                waitingPeriodMessage =
                    deploymentInfo.pre1_waitlist.settings.branchedCompletionConfig.defaultBranch.conditions.find(
                        cond => Number(cond.key) === 1
                    ).completionMessage;
            } catch (e) {
                waitingPeriodMessage = _get(deploymentInfo, 'pre1_waitlist.settings.completedMessage');
            }

            setStaticMessage(waitingPeriodMessage || supportMessage);
            return;
        }

        // "baseline" survey
        const pre2Min = waitPeriod1Max;
        const pre2Max = addDays(pre2Min, 28);
        const pre2CompletedAt = _get(progressInfo, 'pre2_waitlist.completedAt');
        if (!isFuture(pre2Min) && isFuture(pre2Max) && !pre2CompletedAt) {
            setDeploymentHandle('pre2_waitlist');
            return;
        }

        const courseMin = pre2CompletedAt ? new Date(pre2CompletedAt) : pre2Max;
        const courseMax = addDays(courseMin, 28);
        if (!isFuture(courseMin) && isFuture(courseMax)) {
            setDeploymentHandle('course');
            return;
        }

        const post1Min = courseMax;
        const post1Max = addDays(post1Min, 28);
        const post1CompletedAt = _get(progressInfo, 'post1_waitlist.completedAt');
        if (!isFuture(post1Min) && isFuture(post1Max)) {
            if (!post1CompletedAt) {
                setDeploymentHandle('post1_waitlist');
            } else {
                let completionMessage = '';
                try {
                    completionMessage =
                        deploymentInfo.post1_waitlist.settings.branchedCompletionConfig.defaultBranch.conditions.find(
                            cond => Number(cond.key) === 1
                        ).completionMessage;
                } catch (e) {
                    completionMessage = _get(deploymentInfo, 'post1_waitlist.settings.completionMessage');
                }
                setStaticMessage(completionMessage || supportMessage);
            }

            return;
        }

        const waitPeriod2Min = post1Max;
        const waitPeriod2Max = addDays(waitPeriod2Min, 56);
        if (!isFuture(waitPeriod2Min) && isFuture(waitPeriod2Max)) {
            const closedMessage = _get(deploymentInfo, 'post1_waitlist.settings.closedMessage', supportMessage);
            setStaticMessage(closedMessage);
            return;
        }

        // no max for post2 (as of December 2024)
        const post2Min = waitPeriod2Max;
        const post2CompletedAt = _get(progressInfo, 'post2_waitlist.completedAt');
        if (!isFuture(post2Min)) {
            if (!post2CompletedAt) {
                setDeploymentHandle('post2_waitlist');
            } else {
                const completionMessage = _get(
                    deploymentInfo,
                    'post2_waitlist.settings.completionMessage',
                    supportMessage
                );
                setStaticMessage(completionMessage);
            }

            return;
        }

        const finalMessage = post2CompletedAt
            ? _get(deploymentInfo, 'post2_waitlist.settings.completionMessage')
            : _get(deploymentInfo, 'post2_waitlist.settings.closedMessage');
        setStaticMessage(finalMessage);
    }

    if (isLoading) {
        return <Loader loading />;
    }

    if (staticMessage) {
        return <Html content={staticMessage} />;
    }

    if (deploymentHandle) {
        let onComplete = getData;
        // for some deployments, allow the post-deployment message to show
        // or branching logic to do it's thing, instead of refreshing data right away
        if (['consent', 'course', 'pre1_intervention', 'pre1_waitlist'].includes(deploymentHandle)) {
            onComplete = () => {};
        }
        // In this case, give a few seconds for user to be added as a deployment_respondent
        // to the course before refreshing data
        else if (['pre2_waitlist'].includes(deploymentHandle)) {
            onComplete = async () => {
                await new Promise(res => setTimeout(res, 4000));
                await getData();
            };
        }

        return (
            <DeploymentPlayer
                deploymentHandle={deploymentHandle}
                pawnId={pawnId}
                pawnHash={pawnHash}
                disableLayout
                onComplete={onComplete}
            />
        );
    }

    return <Html content={supportMessage} />;
};

export default Course;
